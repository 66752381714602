import React from 'react'

import Layout from '~components/Layout'
import Typography from '~components/Typography'
import MaxWidthWrapper from '~components/MaxWidthWrapper'
import { NAVIGATION, CONTACT } from '~utils/constants'
import { removeWhiteSpace } from '~utils/helpers'
import { trackContactPhone, trackContactEmail } from '~utils/tracking'

import Header from './Header'
import SubHeader from './SubHeader'
import Text from './Text'

const PagePrivacy = ({ location }) => {
  const metaTags = {
    title: 'Datenschutz',
    description:
      'Datenschutz ist wichtig. Hier erfahren Sie, wie und wozu wir Personendaten bearbeiten.',
    slug: NAVIGATION.PRIVACY.PRIVACY,
    image: {
      url: 'PageHome/HeroHome_01--large',
      alt: 'Tania dehnt die Beinmuskulatur am Zürichsee',
    },
  }

  return (
    <Layout pathname={location.pathname} metaTags={metaTags}>
      <MaxWidthWrapper>
        <Header>Datenschutzerklärung</Header>
        <SubHeader>
          Welche personenbezogenen Daten wir sammeln und warum wir sie sammeln
        </SubHeader>
        <Text>
          Wenn Sie auf diese Webseite zugreifen werden automatisch Informationen
          allgemeiner Natur erfasst. Diese Informationen werden im
          Server-Logfile erfasst und beinhalten die Art des Webbrowsers, das
          verwendete Betriebssystem, den Domainnamen Ihres
          Internet-Service-Providers, Ihre IP-Adresse und ähnliches.
        </Text>
        <Typography>Dies aus folgendem Grund:</Typography>
        <ul>
          <Typography as="li">
            Sicherstellung eines problemlosen Verbindungsaufbaus der Website
          </Typography>
          <Typography as="li">
            Sicherstellung einer reibungslosen Nutzung unserer Website
          </Typography>
          <Typography as="li">
            Auswertung der Systemsicherheit und -stabilität sowie
          </Typography>
          <Typography as="li">zu weiteren administrativen Zwecken</Typography>
        </ul>
        <Text>
          Ihre Daten werden nicht verwendet, um Rückschlüsse auf Ihre Person zu
          ziehen. Informationen dieser Art werden lediglich statistisch
          ausgewertet, um unseren Internetauftritt und die dahinterstehende
          Technik zu optimieren.
        </Text>
        <SubHeader>Speicherdauer</SubHeader>
        <Text>
          Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung
          nicht mehr erforderlich sind. Dies ist für die Daten, die der
          Bereitstellung der Webseite dienen, grundsätzlich der Fall, wenn die
          jeweilige Sitzung beendet ist.
        </Text>
        <SubHeader>Kontaktformulare</SubHeader>
        <Text>
          Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen
          Kommunikation mit Ihnen gespeichert.
        </Text>
        <SubHeader>Verwendung von Google Analytics</SubHeader>
        <Text>
          Diese Website benutzt Google Analytics, einen Webanalysedienst der
          Google LLC (nachfolgend: „Google“). Google Analytics verwendet sog.
          „Cookies“, also Textdateien, die auf Ihrem Computer gespeichert werden
          und die eine Analyse der Benutzung der Webseite durch Sie ermöglichen.
          Im Auftrag des Betreibers dieser Website wird Google diese
          Informationen benutzen, um Ihre Nutzung der Webseite auszuwerten, um
          Reports über die Webseitenaktivitäten zusammenzustellen und um weitere
          mit der Websitenutzung und der Internetnutzung verbundene
          Dienstleistungen gegenüber dem Webseitenbetreiber zu erbringen. Die im
          Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse
          wird nicht mit anderen Daten von Google zusammengeführt.
          <br />
          Die Zwecke der Datenverarbeitung liegen in der Auswertung der Nutzung
          der Website und in der Zusammenstellung von Reports über Aktivitäten
          auf der Website. Auf Grundlage der Nutzung der Website und des
          Internets sollen dann weitere verbundene Dienstleistungen erbracht
          werden.
        </Text>
        <SubHeader>Kontakt-Informationen</SubHeader>
        <Text>Für datenschutzrelevante Anliegen wenden Sie sich bitte an:</Text>
        <Text>
          Tania Ferreira
          <br />
          Hardturmstrasse 126, 8005 Zürich
          <br />
          <Typography
            as="a"
            href={`tel:${removeWhiteSpace(CONTACT.PHONE)}`}
            onClick={trackContactPhone}
          >
            {CONTACT.PHONE}
          </Typography>
          <br />
          <Typography
            as="a"
            href={`mailto:${CONTACT.EMAIL}`}
            onClick={trackContactEmail}
          >
            {CONTACT.EMAIL}
          </Typography>
          <br />
        </Text>
        <Text>
          Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine
          E-Mail oder wenden Sie sich direkt an die für den Datenschutz zu
          Beginn der Datenschutzerklärung aufgeführten, verantwortlichen Person
          in unserer Organisation.
        </Text>
        <SubHeader>Wie wir Ihre Daten schützen</SubHeader>
        <Text>
          SSL-Verschlüsselung
          <br />
          Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen,
          verwenden wir dem aktuellen Stand der Technik entsprechende
          Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
        </Text>
        <SubHeader>Änderungen</SubHeader>
        <Text>
          Wir können diese Datenschutzerklärung jederzeit ohne Vorankündigung
          anpassen. Es gilt die jeweils aktuelle, auf unserer Website
          publizierte Fassung.
        </Text>
        <Text>Zürich, 29.07.2020</Text>
      </MaxWidthWrapper>
    </Layout>
  )
}

export default PagePrivacy
