import styled from 'styled-components'

import { media } from '~styles/mediaQuery'
import Typography from '~components/Typography'

const Header = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing[32]};
  ${media.mq234`
    padding-top: ${({ theme }) => theme.spacing[64]};
  `};
`

Header.defaultProps = {
  type: 'header2',
  as: 'h1',
}

export default Header
