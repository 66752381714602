import styled from 'styled-components'

import Typography from '~components/Typography'

const SubHeader = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing[8]};
`

SubHeader.defaultProps = {
  type: 'header3',
  as: 'h2',
}

export default SubHeader
